<template>
    <div>
        <van-cell-group title="通道">
            <van-cell title="通道" :value="model.serialNumber + '(' + model.tongDao + ')'"></van-cell>
            <van-cell title="设置温度" :value="model.meiSiXiaoShiJiangWen + '℃'" value-class="font-red"></van-cell>
        </van-cell-group>
        <van-cell-group :title="'前4小时(' + model.prev + ')'">
            <van-cell title="平均温度" :value="model.prevTemperature + '℃'" value-class="font-red"></van-cell>
        </van-cell-group>

        <van-cell-group :title="'后4小时(' + model.next + ')'">
            <van-cell title="平均温度" :value="model.nextTemperature + '℃'" value-class="font-red"></van-cell>
        </van-cell-group>
    </div>
</template>
<script>
import Vue from "vue";
import Vant from "vant";
Vue.use(Vant);
export default {
    data() {
        return { model: {} };
    },
    mounted() {
        document.title = "每4小时报警";
        let that = this;
        that.axios.post("WXCW03_BJ/GetModel_BJRecord_4Hours", { onlyInt: parseInt(that.$route.query.id) }).then((response) => {
            that.model = response.data.data;
        });
    },
};
</script>

<style scoped>
.font-red {
    color: red;
    font-weight: bolder;
}
</style>